import {GlobalContext} from "../../GlobalContext";
import {Button, Col, Container, Form, FormControl, FormFile, FormGroup, FormLabel, Row} from "react-bootstrap";
import React from "react";

export class AdminFileUploadForm extends React.Component {
    static contextType = GlobalContext;

    fileTypes = [
        {
            label: "Auth Tasks",
            uri: "/api/emrtaskcsv/upload.json"
        },
        {
            label: "Auth (POM)",
            uri: "/api/authcsv/upload.json"
        },
        {
            label: "Appointments (Occurred)",
            uri: "/api/apptcsv/upload.json"
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            fileType: this.fileTypes[0],
            selectedFileName: "",
            validated: false
        }
    }

    fileTypeChanged = (e) => {
        let uri = e.target.value;
        this.setState({fileType: this.fileTypes.find(ft => ft.uri === uri)});
    }

    fileSelected = (e) => {
        this.setState({selectedFileName: e.target.value.replace(/^.*[\\/]/, '')});
    }

    submit = (e) => {
        const form = e.currentTarget;
        let valid = form.checkValidity();
        e.preventDefault();
        e.stopPropagation();
        this.setState({validated: true});

        if (valid) {
            let input = document.getElementById("file-input");
            let data = new FormData()
            data.append('file', input.files[0]);

            fetch(this.state.fileType.uri, {
                method: 'POST',
                body: data,
                headers: {
                    "Authorization": "Bearer " + this.context.token,
                    "Accept": "application/json"
                }
            }).then(response => {
                //accept anything between 200-300, and 400 for 'bad input' responses
                if ((response.status >= 200 && response.status < 300) || response.status === 400) {
                    return Promise.resolve(response);
                } else if (response.status === 403) {
                    //log them out if access denied
                    this.context.userDidLogout();
                    return Promise.reject(new Error("Access Denied"));
                }
                return Promise.reject(new Error(response.statusText));
            }).then(resp => resp.json()).then(d => {
                console.log(d);
                if (d.header.status === "OK") {
                    input.value = null;
                    this.setState({selectedFileName: ""});
                }
            })
                .catch((err) => {
                    console.log(err.message);
                    if ("Access Denied" !== err.message) {
                        //internal
                        this.context.toast('Error', 'Oops! Something went wrong on our end.');
                        return Promise.resolve({});
                    }
                })
                .finally(() => {
                    this.context.resetLogoutTimer();
                    this.setState({validated: false});
                });
        }
    }

    render() {
        return <Container>
            <Form noValidate validated={this.state.validated} onSubmit={this.submit}>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>CSV File Type:</FormLabel>
                            <FormControl as={"select"} custom value={this.state.fileType.uri} onChange={this.fileTypeChanged}>
                                {
                                    this.fileTypes.map(ft => <option key={ft.uri} value={ft.uri}>{ft.label}</option>)
                                }
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Select a file to upload:</FormLabel>
                            <Form.File
                                required
                                onChange={this.fileSelected}
                                id={"file-input"}
                                label={this.state.selectedFileName ? this.state.selectedFileName : "Select a file"}
                                custom
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col className={"text-right"} md={6}>
                        <Button type={"submit"} variant={"dark"}>Upload</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    }
}