import React from "react";
import {Col, Container, Dropdown, OverlayTrigger, Popover, Row, Table} from "react-bootstrap";
import {Glyph} from "../common/Glyph";
import moment from "moment-timezone";
import {GlobalContext} from "../../GlobalContext";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import {ContextMenu} from "../common/ContextMenu";

export class AuthAdminDashboard extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            recentFileImports: [],
            ehrTaskAnomalies: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.context.apiRequest('GET', '/workTasks/admin/auth').then(d => {
            if (d) {
                this.setState({...d});
            }
        });
    }

    getEhrTaskAnomalyHint = (a) => {

        let minutesSinceImport = a.importTime ? moment().diff(moment(a.importTime), 'minutes') : null;
        let result = {
            code: 'UNKNOWN',
            msg: 'Contact support (Scott).'
        };
        if (!a.externalPatientId2) {
            result = {
                code: "NO PERSON NUMBER",
                msg: "No tile created because SMART has no pt with patient #" + a.personNumber + ". Make any update to the pt record in EHR to push an update to SMART."
            }
        } else if (a.assignedTo !== 'AUTHORIZATIONS DPT.' && a.assignedTo !== 'ELIGIBILITY AND BENEFITS') {
            result = {
                code: "INVALID ASSIGNED-TO",
                msg: "No tile created because only supported values for 'assigned to' are 'AUTHORIZATIONS DPT.' and 'ELIGIBILITY AND BENEFITS'."
            }
        } else if (minutesSinceImport && minutesSinceImport < 10) {
            result = {
                code: "TILE IN-PROCESS",
                msg: "Tile has not been created yet. The job to create tiles runs every 10 minutes."
            }
        }
        return result;
    }

    ignoreEhrTaskAnomaly = (id) => {
        this.context.apiRequest('PATCH', `/emrTasks/${id}`, {id, ignore: 1})
            .then(this.loadData);
    }

    render() {
        return <>
            <Row>
                <Col>
                    <h4>Recent File Imports</h4>
                    <div style={{height: "300px", overflowY: "auto"}}>
                        <Table striped size={"sm"}>
                            <thead>
                            <tr>
                                <th>
                                    Date/Time
                                </th>
                                <th>
                                    File
                                </th>
                                <th className={"text-center"}>
                                    Status
                                </th>
                                <th>
                                    Message
                                </th>
                                <th className={"text-center"}>
                                    Total Lines
                                </th>
                                <th className={"text-center"}>
                                    Unique Lines
                                </th>
                                <th className={"text-center"}>
                                    Tasks In DB
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.recentFileImports.map(f => <tr key={`rfi-${f.id}`}>
                                    <td className={"text-nowrap"}>{moment(f.attemptedDateTime).format('M/D/YYYY  h:mm A')}</td>
                                    <td>{f.filename}</td>
                                    <td className={"text-center"}><Glyph name={f.success ? 'check-circle-fill' : 'x-circle-fill'}
                                                                         variant={f.success ? 'success' : 'danger'}/></td>
                                    <td>{f.message}</td>
                                    <td className={"text-center"}>{f.totalLines}</td>
                                    <td className={"text-center"}>{f.uniqueLines}</td>
                                    <td className={"text-center"}>{f.emrTaskCount}</td>
                                </tr>)
                            }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <Row className={"mt-4"}>
                <Col>
                    <h4>EHR Task Anomalies</h4>
                    <div style={{height: "300px", overflowY: "auto"}}>
                        <Table striped size={"sm"}>
                            <thead>
                            <tr>
                                <th>
                                    Task SID
                                </th>
                                <th>
                                    Patient #
                                </th>
                                <th>
                                    Created
                                </th>
                                <th>
                                    Assigned To
                                </th>
                                <th>
                                    Task Type
                                </th>
                                <th>
                                    Problem/Message
                                </th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.ehrTaskAnomalies.map(a => {
                                    let hint = this.getEhrTaskAnomalyHint(a);
                                    let hintElem = <></>;
                                    if (hint) {
                                        hintElem = <>{hint.code} <Glyph name={"info-circle-fill"} variant={"secondary"}
                                                                        label={hint.msg}/></>
                                    }
                                    return <tr key={`a-${a.id}`} className={"parent-hover-parent"}>
                                        <td>{a.externalId}</td>
                                        <td>{a.personNumber}</td>
                                        <td>{moment(a.createdDate).format('M/D/YYYY')}</td>
                                        <td>{a.assignedTo}</td>
                                        <td>{a.taskType}</td>
                                        <td>{hintElem}</td>
                                        <td>
                                            <ContextMenu id={"ehrTaskAnomaliesCM-"+a.id} menuItems={[
                                                {text: "Ignore", onClick: () => this.ignoreEhrTaskAnomaly(a.id)}
                                            ]}/>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </>
    }
}